import React, {useState} from 'react';

function Main(){


    return (

        <div>
            <h1>Main Page</h1>
        </div>
    )
}

export default Main;